.circle {
    /*This creates a 1px solid red border around your element(div) */
    background-color: #FFFFFF;
    height: 100px;
    font-size: 13px ;
    /* border-radius 50% will make it fully rounded. */
    border-radius: 50%;
    -moz-border-radius:50%;
    -webkit-border-radius: 50%;
    width: 100px;
    text-align: center;
    padding-top: 4%;
   
}

.circleText{
        color:white;
        font-weight: 700;

    
}

.pcpTable{
    tr:nth-child(even) {
        background-color: #dddddd;
      }
}
#startdate_popup{
    margin-left:16px ;
    width:261px;
    height: 40px;
}
#enddate_popup{
    width:261px;
    height: 40px;
    margin-left: -138px; 
}
#second_engineer-select-label {
    margin-left: -139px;
    margin-top: 16px;
}
#no_routings_changed{
    margin-left: 14px;
}
.react-datepicker{
    margin-top:-150px;

}
#first_engineer{
    margin-left: 18px;
    margin-top: 16px;
}
#workstack_popup [aria-labelledby="popup-dialog-title"]{
    overflow:visible !important;
 
}