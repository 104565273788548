#instruction h1{
    font-size: 17px !important;
    margin-top: 10px;
    margin-left: 36px;
    font-weight: 100;
}
#instruction h2{
font-size: 15px;
    font-weight: 800;
    margin-left: 36px;
    margin-top: -5px;
}
#instruction h3{
font-size: 14px;
    font-weight: 500;
    margin-top: -17px;
    margin-left: 36px;
}
#instruction h4 {
    font-size: 15px;
    font-weight: 800;
    margin-left: 36px;
    margin-top: -64px;
    padding-top: 31px;
}
#instruction h5 {
    font-size: 15px;
    font-weight: 800;
    margin-left: 36px;
    margin-top: -9px;
}
#instruction h6 {
font-size: 14px;
    font-weight: 500;
    margin-top: -28px;
    margin-left: 36px;
}
#engineers h1 {
    font-size: 17px !important;
    margin-top: 6px;
    margin-left: 36px;
}
#engineers h2 {
    font-size: 15px;
    margin-left: 36px;
    margin-top: 15px;
}
#engineers h3 {
    font-size: 14px;
    font-weight: 500;
    margin-top: -8px;
    margin-left: 36px;
}
#engineers h4 {
        font-size: 14px;
        font-weight: 500;
        margin-top: -72px;
        margin-left: 36px;
    }
 .css-1aquho2-MuiTabs-indicator {
    background-color: aliceblue !important;
} 
#lead h1 {
    font-size: 17px !important;
    margin-top: 6px;
    margin-left: 36px;
}
#lead h2 {
    font-size: 15px;
    margin-top: -14px;
    margin-left: 36px;
}
#lead h3 {
    font-size: 14px;
    font-weight: 500;
    margin-top: -11px;
    margin-left: 36px;
}
#lead h4 {
    font-size: 14px;
    font-weight: 500;
    margin-top: -18px;
    margin-left: 36px;
}
#lead h5 {
    font-size: 14px;
    font-weight: 500;
    margin-top: -34px;
    margin-left: 36px;
}
#planner h1 {
    font-size: 17px !important;
    margin-top: 6px;
    margin-left: 36px;
}
#planner h2 {
    font-size: 14px;
    font-weight: 500;
    margin-top: -4px;
    margin-left: 36px;
}
#planner h3 {
    font-size: 14px;
    font-weight: 500;
    margin-top: -56px;
    margin-left: 36px;
}
#planner h4 {
    font-size: 14px;
    font-weight: 500;
    margin-top: -11px;
    margin-left: 36px;
}
#planner h5 {
    font-size: 14px;
    font-weight: 500;
    margin-top: -19px;
    margin-left: 36px;
}
#engineers {
    background: white;
    border: 3px solid whitesmoke;
}
#lead {
    background: white;
    border: 3px solid whitesmoke;
}
#planner {
    background: white;
    border: 3px solid whitesmoke;
}
.css-1d9ye53-MuiTypography-root {
    color:white !important;
    font-size: 14px !important;
    margin-left:-20px !important;
}

.rdw-fontfamily-dropdown{
    width: 140px !important;
}