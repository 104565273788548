.css-ttn1xo{
    overflow: hidden;
}

.css-1xnbuy2 {
    overflow: hidden;

}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    overflow:visible !important;
}
#check_yes_no{
    width:"100px" !important;
}

#startdate_popup{
    margin-left:16px ;
    width:261px;
    height: 40px;
}
#enddate_popup{
    width:261px;
    height: 40px;
    margin-left: -138px; 
}
#second_engineer-workstack-label {
    margin-left: -157px;
    margin-top: -4px;
}
#no_routings_changed{
    margin-left: 1px;
}
.react-datepicker{
    margin-top:-150px;
}
#workstack_popup [aria-labelledby="popup-dialog-title"]{
    overflow:visible !important;
 
}