.css-1xnbuy2 {
    overflow: hidden;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.css-ttn1xo {
    overflow: hidden;
    padding: 24px 10px !important;
}

.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.3rem !important;
}

/* sortlabel */
.MuiTableSortLabel-icon {
    opacity: 1;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.6);
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #28a745 !important;
    border-radius: 50% !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #dce5de !important;
    border: 2px solid #28a745 !important;
    border-radius: 50% !important;
}

.css-117w1su-MuiStepIcon-text {
    fill: #28a745 !important;
    font-size: 0.75rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.css-ahj2mt-MuiTypography-root {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    padding: 0;
}

div.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: yellow;
    padding: 50px;
    font-size: 20px;
}


#exclude_solution_no {
    padding-top: 20px !important;
}