#created_at {
    margin-left: -10px !important;
}

.comments_assign {
    margin-left: 16px !important;

}

.planner_name_assign {
    margin-left: 16px !important;
}

#assigned_for {
    margin-left: -10px !important;
}

#No_jumpers {
    margin-left: 6px !important;
}
.status_assign {
    margin-left: 16px !important;
}
#cable {
    margin-left: 6px !important;
}
 label[for="routings_changed"] {
  margin-left: -3px !important;
  }
#engineer_name{
  margin-left: 6px !important;
}
.assigned_date {
    margin-left: 16px !important;
}
#demo-select-small{
    font-size: 13px;
}
#demo-select-small-label{
    font-size: 12px;
    top :3px;
}
